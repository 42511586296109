 const assetPrefix = "https://bm-h5-cp.oss-cn-shanghai.aliyuncs.com/assets";

export const CommonAssets = {
  background: `${assetPrefix}/background.png`,
  house: `${assetPrefix}/house.png`,
  logo: `${assetPrefix}/logo.png`,
  starturns: `${assetPrefix}/starturns.png`,
  keys: `${assetPrefix}/keys.png`,
  code: `${assetPrefix}/qr.png`,
  done: `${assetPrefix}/done.png`,
  close: `${assetPrefix}/close.svg`,
  arrow: `${assetPrefix}/arrow.svg`,
  banner_frame: `${assetPrefix}/banner-frame.png`,
  floor1: `${assetPrefix}/floor1.png`,
  floor2: `${assetPrefix}/floor2.png`,
  floor3: `${assetPrefix}/floor3.png`,
  star_a: `${assetPrefix}/star_a.svg`,
  star_b: `${assetPrefix}/star_b.svg`,
  rule_card: `${assetPrefix}/rule_card.svg`,
};
export const LandingAssets = {
  banner: `${assetPrefix}/landing/banner.svg`,
  door: `${assetPrefix}/landing/door.png`,
  treeLeft: `${assetPrefix}/landing/golden tree left.png`,
  treeRight: `${assetPrefix}/landing/golden tree right.png`,
  treeTop: `${assetPrefix}/landing/golden tree top.png`,
  icon_bag: `${assetPrefix}/landing/icon_bag.svg`,
};

export const FlipAssets = {
  banner: `${assetPrefix}/game-flip/baner.png`,
  cardA: `${assetPrefix}/game-flip/a.png`,
  cardB: `${assetPrefix}/game-flip/b.png`,
  cardBack: `${assetPrefix}/game-flip/back.png`,
  cardNull: `${assetPrefix}/game-flip/null.png`,
  resultTitle: `${assetPrefix}/game-flip/result_title.svg`,
  resultA: `${assetPrefix}/game-flip/resulta.png`,
  resultB: `${assetPrefix}/game-flip/resultb.png`,
  title: `${assetPrefix}/game-flip/title.svg`,
};

export const MatchAssets = {
  circle1: `${assetPrefix}/game-match/Ellipse 9.png`,
  circle2: `${assetPrefix}/game-match/Ellipse 11-1.png`,
  circle3: `${assetPrefix}/game-match/Ellipse 11.png`,
  circle4: `${assetPrefix}/game-match/Ellipse 13.png`,
  circle5: `${assetPrefix}/game-match/Ellipse 15.png`,
  info: `${assetPrefix}/game-match/info.svg`,
  tip: `${assetPrefix}/game-match/tip.svg`,
  rect1: `${assetPrefix}/game-match/Rectangle 290-1.png`,
  rect2: `${assetPrefix}/game-match/Rectangle 290-2.jpg`,
  rect3: `${assetPrefix}/game-match/Rectangle 290-3.jpg`,
  rect4: `${assetPrefix}/game-match/Rectangle 290-4.jpg`,
  rect5: `${assetPrefix}/game-match/Rectangle 290.jpg`,
  resultTitle: `${assetPrefix}/game-match/title_result.svg`,
  title: `${assetPrefix}/game-match/title.svg`,
  hand: `${assetPrefix}/game-match/hand.svg`,
};

export const GiftAssets = {
  title: `${assetPrefix}/game-card/title.svg`,
  coupon: `${assetPrefix}/game-card/coupon.png`,
  card1: `${assetPrefix}/game-card/card.jpg`,
  card2: `${assetPrefix}/game-card/card2.jpg`,
  card3: `${assetPrefix}/game-card/card3.jpg`,
  icon_back: `${assetPrefix}/game-card/icon_back.svg`,
  icon_house: `${assetPrefix}/game-card/icon_house.svg`,
  coupon_s: `${assetPrefix}/game-card/coupon_s.svg`,
};
