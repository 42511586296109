import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CommonAssets } from './Assets';
import * as Assets from './Assets';
import TWEEN from "@tweenjs/tween.js";
import * as Methods from "./utils/methods";
import './App.scss';


console.log(1213)
declare const window: any;
const wxsdk = require("weixin-js-sdk");
const H5URL = 'https://bmcc-testing.31ten.cn';
window.H5URL = H5URL;

const API_URL = "https://wechat.testing.31ten.cn/api/v1/weChatSharing";
const APPID = "wx734a529ddd58632e";
function getSignature(
  originUrl: string,
  pathnameUrl: string,
  callback: Function
) {
  var url =
    API_URL +
    "?action=getsignature&appid=" +
    APPID +
    "&appurl=" +
    originUrl +
    "&signuri=" + encodeURIComponent(pathnameUrl);
  var oReq = new XMLHttpRequest();
  oReq.onload = function (e) {
    callback(e);
  };
  oReq.open("GET", url, true);
  oReq.setRequestHeader("Authorization", "Bearer wfeeq1(08)45GRVD!%@fh67");
  oReq.responseType = "json";
  oReq.send();
}

function initShareOnWechat(wxparams: any) {
  wxsdk.config({
    debug: false,
    appId: wxparams.appId,
    timestamp: wxparams.timestamp,
    nonceStr: wxparams.nonceStr,
    signature: wxparams.signature,
    jsApiList: [
      "checkJsApi",
      "updateAppMessageShareData",
      "updateTimelineShareData",
      "scanQRCode",
    ],
    openTagList: ["wx-open-launch-weapp"],
  });
  wxsdk.ready(function () {
    var shconfig = {
      title: `欢迎来到贝玛格雷葡萄酒庄园！`,
      desc: `进入庄园沉浸体验浪漫法式新意时光`,
      link: Methods.formQuerysIntoUrl(H5URL, { ...Methods.getQueryFromUrl() }),
      imgUrl: "https://bm-h5-cp.oss-cn-shanghai.aliyuncs.com/assets/logo.png",
    };
    wxsdk.updateAppMessageShareData(shconfig);
    wxsdk.updateTimelineShareData(shconfig);
  });
}

const LandingPage = React.lazy(() => import('./pages/landing-page'))
const Homepage = React.lazy(() => import('./pages/home'))
const FlipGame = React.lazy(() => import('./pages/game-flip'))
const FlipGameResult = React.lazy(() => import('./pages/game-flip/result'))
const MatchGame = React.lazy(() => import('./pages/game-match'))
const MatchGameResult = React.lazy(() => import('./pages/game-match/result'))
const GiftCard = React.lazy(() => import('./pages/game-card'))
const CardShare = React.lazy(() => import('./pages/game-card/result'))

const allAssets: string[] = []
for (const keys in Assets) {
  for (const url in Assets[keys]) {
    allAssets.push(Assets[keys][url]);
  }
}
const allLoader = allAssets.map(i => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = i;
    if (img.complete) {
      res(null)
    } else {
      img.onload = () => res(null);
      img.onerror = () => res(null);
    }
  })
})


function App() {
  console.log(Methods.getQueryFromUrl());

  const [preloaded, setPreloaded] = useState(false)
  useLayoutEffect(() => {

    document.getElementById('root').style.backgroundImage = `url(${CommonAssets.background})`;
    document.getElementById('root').style.backgroundAttachment = 'fixed';
    document.getElementById('root').style.backgroundSize = 'cover';
    document.getElementById('root').style.backgroundPosition = 'center';
  }, [])

  useEffect(() => {

    setTimeout(() => {
      if (Methods.isWX()) {
        getSignature(H5URL, window.location.pathname + window.location.search, function (e: any) {
          e.target.response && initShareOnWechat(e.target.response.content);
        });
      }
    }, 100);

    let animator = requestAnimationFrame(animate);
    function animate(time) {
      animator = requestAnimationFrame(animate)
      TWEEN.update(time)
    }

    Promise.all(allLoader).then(() => {
      setPreloaded(true);
    })
    const viewed = localStorage.getItem('viewed');
    let fallbackFlag = viewed === null;
    if (fallbackFlag) {
      window.location.replace('/')
    }
    localStorage.setItem('viewed', 'true')
    return () => {
      cancelAnimationFrame(animator);
    }
  }, [])
  return (
    preloaded ?
      <BrowserRouter >
        <Suspense fallback={
          <div>

            <img className='bm-loader' src={CommonAssets.starturns} alt={''}></img>
          </div>
        }>

          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/home' element={<Homepage />} />
            <Route path='/game1' element={<FlipGame />} />
            <Route path='/game1/result' element={<FlipGameResult />} />
            <Route path='/game2' element={<MatchGame />} />
            <Route path='/game2/result' element={<MatchGameResult />} />
            <Route path='/game3' element={<GiftCard />} />
            <Route path='/game3/result' element={<CardShare />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      :
      <div>
        <img className='bm-loader' src={CommonAssets.starturns} alt={''}></img>
      </div>

  );
}

export default App;