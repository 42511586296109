import { Tween } from "@tweenjs/tween.js";
export function TweenObj(target, { from = undefined, to, time = 1000 }) {
  //根据 to 获取需要计算属性的单位，例如 ‘100%’ 中的 %
  const keyunitMap: any = {};
  for (const attr in to) {
    if (typeof to[attr] === "string") {
      const toEdit = to[attr].toString();
      const val = parseFloat(toEdit).toString();
      const unit = toEdit.replace(val, "");
      if (unit) {
        keyunitMap[attr] = unit;
      }
    }
  }

  if (!from) {
    //如果不给初始值,取元素当前状态
    from = {};
    const currentStyle = window.getComputedStyle(target);
    for (const attr in to) {
      switch (attr) {
        case "scaleX":
        case "scaleY":
          from[attr] = 1;
          break;
        default:
          from[attr] = currentStyle[attr];
          break;
      }
    }
  }
  for (const attr in from) {
    //tween 只能计算数值，所以先把所有属性变为数值 ‘10.58%’-> 10.58
    if (typeof from[attr] === "string") {
      from[attr] = parseFloat(from[attr]);
    }
  }

  applyCSSObjToTarget(from, target, keyunitMap);
  for (const attr in to) {
    //tween 只能计算数值，所以先把所有属性变为数值 ‘10.58%’-> 10.58
    if (typeof to[attr] === "string") {
      to[attr] = parseFloat(to[attr]);
    }
  }
  // console.log(from);
  // console.log(to);
  return new Tween(from).to(to, time).onUpdate((e) => {
    // console.log(e);
    applyCSSObjToTarget(e, target, keyunitMap);
  });
}
export function applyCSSObjToTarget(e, target, unitMapping: any = {}) {
  if (!target) return;

  //x y 为特例，通过设置translate来实现
  if (
    e.x !== undefined ||
    e.y !== undefined ||
    e.scaleX !== undefined ||
    e.scaleY !== undefined
  ) {
    target.style.transform = `translate(${e.x || 0}${
      unitMapping["x"] || "px"
    },${e.y || 0}${unitMapping["y"] || "px"}) scale(${
      e.scaleX === undefined ? 1 : e.scaleX
    },${e.scaleY === undefined ? 1 : e.scaleY})`;
    //TODO 待优化的属性: rotate, scaleX, scaleY 等等
  }

  for (const attr in e) {
    //   console.log(e[attr]);
    switch (attr) {
      case "x":
      case "y":
        break;
      default:
        target.style[attr] = e[attr] + (unitMapping[attr] || "");
        break;
    }
  }
}
export async function drawImageToCanvas(
  canvas: HTMLCanvasElement,
  path: string,
  x,
  y,
  width,
  height,
  mode?: "aspectFit" | "aspectFill" | "scaleToFill"
) {
  //wrap the draw method
  return new Promise((reslove, reject) => {
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onerror = (e) => {
      console.warn("behavior draw", e);
      reslove(null);
    };
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const distRect = {
        x: x,
        y: y,
        width: width,
        height: height,
      };
      const sourceRect = {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      };
      if (mode && mode !== "scaleToFill") {
        let distRatio = width / height;
        let srcRatio = img.width / img.height;
        switch (mode) {
          case "aspectFill":
            if (distRatio > srcRatio) {
              sourceRect.x = 0;
              sourceRect.y = (img.height - (img.width / width) * height) / 2;
              sourceRect.width = img.width;
              sourceRect.height = (img.width / width) * height;
            } else {
              sourceRect.y = 0;
              sourceRect.x = (img.width - (img.height / height) * width) / 2;
              sourceRect.width = (img.height / height) * width;
              sourceRect.height = img.height;
            }
            break;
          case "aspectFit":
            if (distRatio > srcRatio) {
              distRect.y = y;
              distRect.x = x + (width - (height / img.height) * img.width) / 2;
              distRect.height = height;
              distRect.width = (height / img.height) * img.width;
            } else {
              distRect.x = x;
              distRect.y = y + (height - (width / img.width) * img.height) / 2;
              distRect.width = width;
              distRect.height = (width / img.width) * img.height;
            }
            break;
        }
      }
      ctx.drawImage(
        // path.includes("http") ? e.path : path,
        img,
        sourceRect.x,
        sourceRect.y,
        sourceRect.width,
        sourceRect.height,
        distRect.x,
        distRect.y,
        distRect.width,
        distRect.height
      );
      reslove(null);
    };
    img.src = path;
  });
}
export function drawMultiline(
  ctx: CanvasRenderingContext2D,
  str: string,
  x: number,
  y: number,
  maxWidth: number,
  maxLine: number = -1,
  lineHeight: number = 18
) {
  let lines: any[] = [];
  let prevCount = 0;
  for (let i = 0; i < str.length; i++) {
    const { width } = ctx.measureText(str.slice(prevCount, i));
    if (width > maxWidth) {
      lines.push(str.slice(prevCount, i));
      prevCount = i;
    }
    if (lines.length === maxLine) {
      break;
    }
  }
  if (lines.length === maxLine) {
    lines[maxLine - 1] =
      lines[1].slice(0, lines[maxLine - 1].length - 2) + "……";
  } else {
    lines.push(str.slice(prevCount, str.length));
  }

  lines.forEach((item, idx) => {
    ctx.fillText(item, x, y + lineHeight * idx);
  });
}

const UAParser = require("ua-parser-js");

const ua = new UAParser();
export function isWX(): boolean {
  return /MicroMessenger|micromessenger/i.test(window.navigator.userAgent);
}
export function isIOS(): boolean {
  return ua.getOS().name === "iOS";
}
export function isAND(): boolean {
  return ua.getOS().name === "Android";
}

export function isSafari(): boolean {
  return ua.getBrowser().name.toLowerCase().includes("safari");
}

export function getBrowser() {
  return ua.getBrowser().name;
}
export function formQuerysIntoUrl(url: string, payload: any): string {
  const currentQuery = getQueryFromUrl(url);
  const newQuery: any = { ...currentQuery, ...payload };
  const newSearchArr: string[] = [];
  for (const key in newQuery) {
    newSearchArr.push(`${key}=${newQuery[key]}`);
  }
  return newSearchArr.length > 0
    ? `${url.split("?")[0]}?${newSearchArr.join("&")}`
    : url;
}
export function getQueryFromUrl(url: string = window.location.search): any {
  const str = window.location.search;
  console.log(str)
  if (str) {
    return Object.fromEntries(str.split("&").map((i) => i.split("=")));
  } else {
    return {};
  }
}
export function jdJump() {
  window.location.href = "https://shop.m.jd.com/?shopId=11689974";
}
